<template lang="pug">
	div
		.widget
			.widget-header
				h1.title Tags
				button.btn.btn-link(type="button", @click="loadData()", v-b-tooltip.hover.left, title="Refresh")
					i.la.la-refresh
			.widget-body

				.ov-table-wrapper
					table.table.table-bordered.table-sm.table-hover.table-responsive-sm
						thead
							tr
								th
									.text Tag
								th
									.text Publisher
								th
									.text OS
								th
									.text Banner
								th
									.text Status
								//th &nbsp;
						tbody
							tr(v-if="filteredRecords.length === 0")
								td(colspan="5") No matching records were found
							tr(v-for="r in filteredRecords", :key="r.id")
								td
									entity(type="tag", :id="r.id", :name.sync="r.name")
								td
									span(v-if="r.publisher")
										entity(type="publisher", :id="r.publisher.id", :name.sync="r.publisher.name")
								td
									span.badge.badge-primary(v-if="r.os === 'android'") Android
									span.badge.badge-info(v-if="r.os === 'ios'") iOs
								td
									div.banner-container
										img(v-if="r.banner", :src="r.banner")
								td
									span.badge.badge-secondary(v-if="!r.synced") Pending
									span.badge.badge-success(v-if="r.synced") Synced
								//td.actions
									a.btn.btn-action-default(v-b-tooltip.hover.bottom, title="Edit", @click="editTagModal(r)")
										i.la.la-edit
				paginate(:paginator="paginate", @update-page-size="loadData")

</template>

<style lang="scss">
.banner-container {
  width: 200px;
  img {
    max-width: 100%;
  }
}
</style>

<script>
export default {
  name: 'TagList',
  computed: {
    filteredRecords: function () {
      if (!this.filters.keyword) {
        return this.records;
      }
      let kw = this.filters.keyword.toLowerCase();
      return this.records.filter((r) => {
        return r.id == kw || r.name.toLowerCase().match(kw);
      });
    },
  },
  data() {
    return {
      busy: false,
      currentTag: null,
      paginate: {
        numPages: 0,
        total: 0,
        page: 1,
        limit: 30,
        onPageChange: () => {
          this.loadData();
        },
      },
      filters: {
        keyword: '',
      },
      records: [],
    };
  },
  methods: {
    async loadData() {
      this.busy = true;
      let params = {
        page: this.paginate.page,
        page_size: this.paginate.limit,
        // TODO - add filters (publisher, os)
      };
      try {
        let resp = await this.$ovReq.get('tag/getList', { params });
        this.records = resp.records;
        this.paginate.total = resp.total;
        this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },
  },
  created() {
    this.loadData();
  },
};
</script>
